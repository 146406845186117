<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
      <!-- Card stats -->
    </base-header>

    <b-container fluid class="mt--7">
      <b-row>
        <b-col>
          <b-overlay :show="loading">
            <b-card no-body>
              <b-card-header class="border-1">
                <h3 class="mb-0">申請中タスク一覧</h3>
              </b-card-header>
              <b-table
                :items="tasks"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                stacked="md"
                show-empty
                small
                hover
              >
                <template #cell(name)="row">
                  {{ row.value }}
                </template>
                <template #cell(created_dt)="row">
                  {{ showCreateDt(row.value) }}
                </template>
                <template #cell(approver_list)="row">
                  <span v-for="(approver, idx) in row.value" :key="idx" class="mr-2">{{approver.user_name}}</span>&nbsp;
                </template>
                <template #cell(status)="row">
                  <b-badge v-if="row.value==='01'" variant="light">新&nbsp;規</b-badge>
                  <b-badge v-if="row.value==='02'" variant="danger">差戻し</b-badge>
                  <b-badge v-if="row.value==='10'" variant="info">申請中</b-badge>
                  <b-badge v-if="row.value==='11'" variant="success">承認中</b-badge>
                  <b-badge v-if="row.value==='90'" variant="dark">完了</b-badge>
                </template>
                <template #cell(actions)="row">
                  <b-button size="sm" variant="link" :to="{name: 'task-unresolved-detail', params: {id: row.item.id}}">
                    <b-icon icon="pencil-square" variant="primary"></b-icon>
                  </b-button>
                  <b-button v-if="row.item.status<'10'" size="sm" variant="link" @click="deleteTask(row.item.id, row.item.name)">
                    <b-icon icon="trash" variant="danger"></b-icon>
                  </b-button>
                </template>
              </b-table>
              <b-card-footer>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  size="md"
                ></b-pagination>
              </b-card-footer>
            </b-card>
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { ajax, common, config, constant } from '../../utils';
import Toast from '../../components/Toast';
import { helper } from '../common';

export default {
  data() {
    return {
      loading: true,
      tasks: [],
      fields: [
        { key: 'name', sortable: true, label: '名称' },
        { key: 'created_dt', sortable: true, label: '申請日時' },
        { key: 'approver_list', sortable: true, label: '承認者' },
        { key: 'status', sortable: true, label: 'ステータス', class: 'text-center' },
        { key: 'actions', label: 'Actions', class: 'text-center' },
      ],
      currentPage: 1,
      perPage: config.rowsPerPage,
    }
  },
  computed: {
    totalRows: function() {
      return this.tasks.length;
    },
  },
  created: function () {
    document.title = common.getPageName(this.$route.name);
    this.getUnresolvedTaskList();
  },
  methods: {
    ...mapActions('account', ['getStatistics']),
    getUnresolvedTaskList: function() {
      const vm = this;
      ajax.fetchGet(config.api.task.unresolved_list).then(data => {
        vm.tasks = data;
      }).catch(errors => {
        helper.show_errors(errors);
      }).finally(() => {
        vm.loading = false;
      });
    },
    showCreateDt: function(d) {
      return common.formatDate(new Date(d), 'YYYY/MM/DD hh:mm')
    },
    deleteTask: function(task_id, task_name) {
      if (confirm(`タスク「${task_name}」を削除します、よろしいですか？`)) {
        const vm = this;
        ajax.fetchDelete(common.formatStr(config.api.task.task_detail, task_id)).then(() => {
          Toast.success(constant.SUCCESS.DELETED)
          const index = vm.tasks.findIndex(t => t.id === task_id);
          vm.tasks.splice(index, 1)
          vm.getStatistics();
        }).catch(errors => {
          Toast.errorFromApi(errors)
        })
      }
    },
  },
}
</script>